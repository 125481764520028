
.idos-request {
    background-color: #FFFFFF;
    .MuiCircularProgress-root {
      margin: 0 auto;
      display: block;
      position: relative;
      top: 9rem;
    }
.search {
  width: 50%;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.first-row {
    width: 99%;
    margin: 0 auto;
    border-top: none;
    margin-bottom: 0.5rem;
}
    .first-h5 {
       margin: 1rem 0 1rem 0;
    }
    .radio-div {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        label {
            display: flex;
            align-items: flex-end;
            div {
                margin-left: 0.5rem;
            }
        }
    }
    .date-div {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        .MuiInput-root {
            width: 90%;
            border: 1px solid #707070;
        }
        .MuiInput-underline:before {
            border-bottom: 0 !important;
        }
    }
    .sub-date {
        display: flex;
        justify-content: space-between;
        .react-datepicker-wrapper {
            width: 47%;
        }
    }
    thead {
        background: #1E1E2D;
        color: #FFFFFF;
    }
    td {
        vertical-align: middle;
    }
    tbody {
        color: #1E1E2D;
        .btn {
          border: 0;
          background: #3699FF;
          padding: 0.5rem;
        }
    }
    a {
        color: #707070 !important;
        &:hover {
            text-decoration: underline !important;
        }
    }
    .btn {
        background: #707070;
        color: #FFFFFF !important;
        padding: 0.3rem;
        border-radius: 0.2rem;
    }
}
.logo-img {
  width: 50%;
  margin: 0 auto;
  margin-top: 1rem;
}
.ido-request {
  .MuiCircularProgress-root {
    margin: 0 auto;
    display: block;
    position: relative;
    top: 9rem;
  }
  .card-body {
    padding: 1rem;
  }
  .card-title {
    text-align: center;
  }
    .detail-heading {
      text-align: initial;
    }
    .info-div {
      p {
        font-weight: 600;
        margin-top: 0.3rem;
        margin-bottom: 2px;
      }
    }
    .project-description {
      word-wrap: break-word;
      background-color: #ffffff;
      background-clip: border-box;
      border: 1px solid #EBEDF3;
      border-radius: 0.42rem;
      padding: 1rem;
      width: 98%;
      margin: 0 auto;
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li {
          margin-left: 0.5rem;
        
        }
      }
    }
    .bottom-div {
      display: flex;
      width: 98%;
      margin: 0 auto;
      margin-top: 1rem;
      .sub-bottom {
        word-wrap: break-word;
        background-color: #ffffff;
        background-clip: border-box;
        border: 1px solid #EBEDF3;
        border-radius: 0.42rem;
        padding: 1rem;
        width: 49%;
        p {
          font-weight: 600;
          margin-bottom: 2px;
        }
        div {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .last {
      margin: 0 0 0 auto;
    }
    .back {
      color: #707070;
      font-weight: 600;
      margin-bottom: 1rem;
      display: block;
      img {
        width: 0.7rem;
      }
    }
    .status-div {
      text-align: end;
      margin-bottom: 1rem;
      .btn {
        margin-right: 0.4rem;
        background: transparent;
        border-color: #707070;
        color: #707070;
        &:focus {
          background: #707070 !important;
        color: #ffffff;
        border-color: #707070 !important;
        }
        &:hover {
          background-color: #707070 !important;
          color: #ffffff !important;
          border-color: #707070 !important;
        }
      }
      }
}
.create-ido {
  background: #ffffff;
  padding-top: 1rem;
  .check {
    display: block;
    margin-top: 0.5rem;
  }
  .drop-file {
    border: 1px solid #707070;
    width: 85%;
    height: 17rem;
    padding: 1rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    h3 {
      overflow-wrap: anywhere;
    }
  }
  .scroll {
    >:nth-of-type(1){
      padding: 0.5rem;
    }
  }
  .btn {
    display: block;
    margin: 1rem auto 1rem auto;
  }
}

.ido {
  .back {
    color: #707070;
  }
  .btn {
    border: 0;
    margin-left: 0.52rem;
    margin-bottom: 1rem;
    border-radius: 0;

    &:hover {
      background: #3699FF;
    color: #FFFFFF;
    }
  }
  .active {
    background: #3699FF;
    color: #FFFFFF;
  }
}
.applications {
  text-align: end;
  span {
    font-weight: 400;
  }
}
.winner {
  .btn {
    padding: 0.65rem 1rem;
  }
}
.Toastify__toast--error {
  background: #e74c3c !important;
  color: #FFFFFF !important;
}
.Toastify__close-button  {
  color: #FFFFFF !important;
}
.pagination {
  justify-content: center;
  li {
    padding: 0.8rem;
    border: 0.5px solid #DCDCDC;
  }
  .previous {
    margin-right: 0.8rem;
  }
  .next {
    margin-left: 0.8rem;
  }
  .active {
    background-color: #1E1E2D;
    a {
      color: #FFFFFF !important;
    }
  }
}
#kt_header_menu {
  visibility: hidden;
}
.topbar{
  .topbar-item {
    .btn-lg {
      display: none;
    }
  }
} 
#kt_dashboard_daterangepicker,.dropdown-inline,.navi .navi-item {
  display: none;
  
}
.label-inline {
  display: none;
}
.navi-footer {
  .btn-clean {
    display: none;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.brand-logo {
  img {
    width: 80%;
  }
}
.empty {
  position: relative;
    text-align: center;
    top: 9rem;
}
.winner {
  .empty {
      top: 8rem;
  }
}
.hide-visible {
  visibility: hidden;
}
.preview {
  width: 11rem;
}
.sale-div {
 
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
  padding: 1rem;
  width: 49%;
  .sub-sale {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  p {
    margin-bottom: 0;
    font-weight: 600;
  }
  span {
    margin-top: 0.5rem;
  }
}
.card-rounded-bottom {
  display: none;
}
.custom{
  margin-top: 0rem !important;
}
.action-div {
  display: flex;
  justify-content: end;
  @media (max-width:820px) {
    justify-content: start;
  }
}
.hide {
  visibility: hidden;
}
.back {
  img {
    width: 0.7rem;
  }
}
.info-span {
  display: block;
}

.offcanvas-left {
  display: none;
}
.card-spacer {
  @media (max-width:820px) {
    padding: 0 !important;
  }
}